import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";
import styled from "@emotion/styled";

//components
import Adslot from "./Adslot";
import PostsList from "./PostsList";
import PostsListBizon from "./PostsListBizon";
import BlockList from "./common/BlockList";
import BottomBar from "./BottomBar"

import {
  IS_FEATURE_adunityPartner
} from "./common/constants/constants"
import ScrollToTop from "../helpers/ScrollToTop";

@inject("UIStore")
@observer
export default class Category extends Component {

  render() {
    const { category, store, UIStore } = this.props;
    const categoryBlocks = category.wizard;
    const categoryName = category.name;

    // console.log('[ES] Category', store.status);
    if (store.status !== 'valid') {
      return null;
    }
    // console.log('[ES] Category // render', category, categoryBlocks, categoryName);

    // generuje prefix dla reklam
    const isHomePage = category.OID === 0;
    let adPrefix = "KAT";
    if (category.type === "bliss") {
      adPrefix = "bliss"; //TODO co z tym nazewnictwem?
    } else if (category.type === "trudat") {
      adPrefix = "trudat"; //TODO jw
    } else if (isHomePage) {
      adPrefix = "SG";
    }
    // generuje suffix dla reklam
    const isMaterialPartnera = UIStore.isMaterialPartnera;
    const adSuffix = ((IS_FEATURE_adunityPartner && isMaterialPartnera) ? '_partner' : '');

    // ukrycie billboard_1 jak jest włączony b-one-super
    const hideBillboard1 = (categoryBlocks && categoryBlocks[0] && categoryBlocks[0].type == 'b-one-super');

    return (
      <Fragment key="home-page-container">
        <ScrollToTop />
        <Adslot name={"belka_sticky" + adSuffix} />
        {hideBillboard1 || <Adslot name={adPrefix + "_Billboard_1" + adSuffix} />}

        <div className="hp" data-lastmod={store.lastModification}>
          {false && category.show_category_title &&
            category.type !== "trudat" && (
              <CategoryType isContact={category.slug === "kontakt"}>
                {" "}{categoryName}{" "}
              </CategoryType>
            )}

          <BlockList settings={categoryBlocks} adPrefix={adPrefix} adSuffix={adSuffix} />
        </div>

        {category.last_posts.length > 0 &&
          (
            <PostsListBizon posts={category.last_posts} label="Najnowsze" />
          )
        }
        {window.innerWidth < 996 && <BottomBar />}
      </Fragment>
    );
  }
};

const CategoryType = styled.div`
  background: ${props => props.theme.mainColor};
  text-align: center;
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  padding: 10px;
  padding-bottom: ${props => (props.isContact ? "12px" : "19px")};
  position: relative;
  z-index: -1;
  bottom: -9px;
  margin-bottom: ${props => (props.isContact ? "29px" : "0")};
`;