import React, { Fragment } from "react";
import styled from "@emotion/styled";
import { observable } from "mobx";
import { observer, inject } from "mobx-react";

//import PhotoOnLeft from "./common/PhotoOnLeft";
//import PhotoOnTop from "./common/PhotoOnTop";

import { withlazyload } from './HOCs/WithLazyLoad'

//
import Title from "../redesign/atoms/Title";
import PhotoOnTop from "../redesign/components/PhotoOnTop";
import PhotoOnLeft from "../redesign/components/PhotoOnLeftBig";
import Button from "../redesign/atoms/Button";

@withlazyload({
  height: 800,
  once: true,
  offset: 600
})
@inject("CategoryStore", "UIStore")
@observer
export default class PostsList extends React.Component {

  @observable posts = [];
  @observable nextPage = false;
  @observable pending = false;
  @observable hideButton = false;

  componentDidMount() {
    this.handleClick();
    // this.posts = this.props.posts;
    // console.log(this.posts)
  }

  handleClick = ({
    url = `${window.API_HOST}/pwa-category/${this.props.CategoryStore.currentSlug}`
  } = {}) => {
    this.pending = true;
    fetch(url)
      .then(response => {
        if (!response.ok) return Promise.reject();
        return response.json();
      })
      .then(response => {
        this.pending = false;
        if (typeof response.LAST_POST_DATE === "undefined") {
          this.hideButton = true;
        } else {
          this.nextPage = `${window.API_HOST}/pwa-category/${this.props.CategoryStore.currentSlug}?to_date=${encodeURIComponent(response.LAST_POST_DATE)}`;
        }
        this.posts.length !== 0 ? this.posts = [...this.posts, ...response.category.last_posts] : this.posts = response.category.last_posts
        // console.log({ response, nextPage: this.nextPage })
      })
      .catch(() => {
        this.pending = false;
        // console.log("no match found")
      });
  };

  render() {
    const { UIStore, posts } = this.props;
    const isDesktop = window.innerWidth > 996;
    return (
      <div>
        <Wrapper className={"hp"}>
          <Fragment>
            {posts && posts.length > 0 && (
              <div className="container">
                <ArticlesSection>
                  <Title withDots size="large">
                    {this.props.label}
                  </Title>
                  {this.posts.map((article, i) => {
                    return <div key={i}
                    >
                      {isDesktop ?
                        <>
                          <PhotoOnLeft article={article} />
                          {i !== this.posts.length - 1 && <ListSeparator />}
                        </>
                        :
                        <>
                          <PhotoOnTop article={article} />
                          {i !== this.posts.length - 1 && <ListSeparator />}
                        </>
                      }
                    </div>
                  })}
                  {!this.hideButton && posts && posts.length && (
                    <ButtonWrapper>
                      <Button
                        text="Pokaż więcej"
                        starPosition="none"
                        disabled={this.pending}
                        onClick={() => this.handleClick({ url: this.nextPage })}
                      ></Button>
                    </ButtonWrapper>)}
                </ArticlesSection>
              </div>
            )}
          </Fragment>
        </Wrapper>
        {/* 
          <SideSection>
          </SideSection>
        */}
      </div>
    );
  }
}

const Wrapper = styled.div`
  /* margin: 20px 10px 10px 10px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 1px solid #F5F5F5;
`;

const ArticlesSection = styled.div`
  margin-bottom: 40px;
  max-width: 840px;
`;

const SideSection = styled.span`
  display: none;
  height: 100%;
  @media (min-width: 996px) {
    display: block;
    align-self: flex-start;
    padding-top: 58px;
  }
`

const Header = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin: 15px 0 10px 0;
  @media (min-width: 996px) {
    font-size: 26px;
    margin: 10px 0 5px 0;
  }
  span {
    color: ${props => props.theme.mainColor};
  }
`;

const ListSeparator = styled.span`
  display: block;
  width: 100%;
  height: 1px;
  background-color: #E5E5E5;
  margin: 12px 0 24px 0;
  @media (min-width: 996px) {
    margin: 40px 0;
  }
`

export const ButtonWrapper = styled.div`
  margin: 40px auto;
  display: flex;
  justify-content: center;
`;
