import React from "react";
import Link from "../../components/Link";
import Media from "../../components/common/Media";
import { styled } from "../../stitches.config";
import CategoryBadge from "../CategoryBadge";
import TruncatText from "../atoms/TruncatText";
import { bodyLarge, body, h3 } from "../commonStyles/Fonts";
interface PhotoOnLeftProps {
    image: string;
    article?: any;
}
const PhotoOnLeft: React.FC<PhotoOnLeftProps> = ({
    image,
    article,
}) => {
    return (
        <StyledPhotoOnLeft>
            <Link to={article.url}>
                <StyledImage>
                    <Media
                        // media={media}
                        src={image || article.picture.img}
                        preserveRatio={false}
                        aspectRatio={1.5}
                        width={300}
                        height={200}
                    />
                    {article.category.name && (
                        <BadgeWrapper>
                            <CategoryBadge text={article.category.name} />
                        </BadgeWrapper>
                    )}
                </StyledImage>
                <TextWrapper>
                    <Title className={h3()}>{article.title}</Title>
                    <Lead>
                        <TruncatText text={article.lead} maxLength={162} />
                    </Lead>
                </TextWrapper>
            </Link>
        </StyledPhotoOnLeft>
    );
};

const StyledImage = styled("div", {
    flexShrink: 0,
    position: "relative",
    "& img": {
        objectFit: "cover",
        width: "100%",
    },
});
const BadgeWrapper = styled("span", {
    display: "none",
    "@bp4": {
        display: "block",
        position: "absolute",
        bottom: "16px",
        left: "16px",
        zIndex: 3,
    },
});
const Lead = styled("p", {
    display: "none",
    "> span": {
        textDecoration: "none",
    },
    "@bp4": {
        display: "block",
        padding: 0,
        margin: "8px 0 0 0",
        fontWeight: "600",
        fontFamily: "$grotesk",
    },
});
const TextWrapper = styled("div", {
    color: "$grayscale100",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "16px",
});
const StyledPhotoOnLeft = styled("div", {
    "& a": {
        width: "100%",
        display: "flex",
        flexDirection: "row",
    },
});
const Title = styled("h4", {
    padding: 0,
    margin: 0,
    fontFamily: "$grotesk",
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: "0px",
    fontWeight: "600",
    color: "$grayscale100",
    textDecoration: "none !important",
    "@bp4": {
        fontSize: 24,
        lineHeight: "30px",
        letterSpacing: "0.2px",
        fontWeight: "700",
    },
});

export default PhotoOnLeft;
