import React from "react";
import { styled } from "../../stitches.config";
import DotsIcon from "../assets/icons/dots-orange-icon.svg";

interface TitleProps {
  type?: "normal" | "high" | "red" | "black" | 'transparent';
  size?: "extrasmall" | "small" | "normal" | "large" | "big";
  withDots?: boolean;
}

const Title: React.FC<TitleProps> = ({
  type = "normal",
  size = "normal",
  children,
  withDots = false,
  ...props
}) => {
  if (withDots) {
    return (
      <StyledDotsWrapper>
        <svg width="12" height="30" viewBox="0 0 12 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="6" cy="6" rx="6" ry="6" />
          <circle cx="6" cy="24" r="6" />
        </svg>
        <StyledTitle className="title" color={type} size={size}>
          {children}
        </StyledTitle>
      </StyledDotsWrapper>
    );
  }
  return (
    <StyledTitle color={type} size={size} margin>
      {children}
    </StyledTitle>
  );
};

const StyledDotsWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  marginTop: 32,
  marginBottom: 24,

  "@bp4": {
    marginTop: 80,
    marginBottom: 40,
  },

  "svg": {
    fill: "$primary",
    marginRight: 12,
  },

  "& img": {
    paddingRight: 12,
  },

  "& p": {
    marginTop: 0,
    marginBottom: 0,
  }
});

const StyledTitle = styled("p", {
  color: "$grayscale100",
  padding: "0 2px",
  fontFamily: "$grotesk",
  fontWeight: "$7",
  variants: {
    color: {
      normal: { backgroundColor: "$grayscale0" },
      high: { backgroundColor: "$primary", color: "$grayscale0" },
      red: { backgroundColor: "$aszdziennikPrimary", color: "$grayscale0" },
      black: { backgroundColor: "$grayscale100", color: "$grayscale0" },
      transparent: { backgroundColor: "transparent", color: "$grayscale100" },
    },
    size: {
      extrasmall: {
        fontSize: 16,
        lineHeight: 24,
        fontWeight: "$6",
      },
      small: {
        fontSize: 18,
        lineHeight: 24,
      },
      normal: {
        fontSize: 24,
        lineHeight: 30,
      },
      large: {
        fontSize: 24,
        lineHeight: "30px",
        "@bp4": {
          fontSize: 38,
          lineHeight: "42px",
        },
      },
      big: {
        fontSize: 42,
        lineHeight: 38,
      }
    },
    margin: {
      true: {
        marginTop: 32,
        marginBottom: 24,
        "@bp4": {
          marginTop: 80,
          marginBottom: 40,
        },
      }
    }
  }
})

export default Title;
